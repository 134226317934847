/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2018 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

var $ = require('jquery');
var _ = require('underscore');
var ajax = require('./ajax');
var querystring = require('./querystring');
var keyCodes = require('./keyCodes');

var longTouchDelay = 500;

$.behaviors('.listView', listView);

// ListView is fairly complicated, so we split up the implentation into several parts:
//
//   ListViewDOM       -- Tracks the various HTML elements inside list view (cells, rows, action buttons, etc).
//   ListViewExpansion -- Handles expanding rows
//   ListViewMouse     -- Handle mouse hover
//   ListViewKeys      -- Handle keyboard controls


function ListViewDOM(elt) {
    this.elt = $(elt);
    this.cells = this.elt.children().not('.listView-secondaryRow');
    this.columnCount = parseInt(this.elt.css('column-count'));
    this.headerRowCount = 0;
    this.rowCount = 0;
    this.hoverRow = null; // row being hovered over by the mouse
    this.contextMenuRow = null; // row with an active context menu
    this.checkAll = $('.checkAll', elt);
    this.dropdownMenus = $('.dropdownMenu', elt);
    this.dropdownMenusByRow = {};
    this.showDetailsByRow = {};
    this.walkCells();
    this.dropdownMenus.on('shown', this.onDropdownShown.bind(this));
    this.dropdownMenus.on('hidden', this.onDropdownHidden.bind(this));
}

ListViewDOM.prototype = {
    walkCells: function() {
        for(var i=0; ; i++) {
            var cells = this.cellsForRow(i);
            if(cells.length == 0) {
                return;
            }
            cells.data('row', i);
            this.rowCount++;
            if(cells.is('.listView-header')) {
                this.headerRowCount++;
            }
            this.dropdownMenusByRow[i] = $('.dropdownMenu', cells);
            this.showDetailsByRow[i] = $('.listView-showDetails', cells);
        }
    },
    cellsForRow: function(row) {
        if(row === null) {
            return $();
        }
        var start = row * this.columnCount;
        var end = start + this.columnCount;
        var rv = this.cells.slice(start, end);
        // Add the secondary row, if it is present
        rv = rv.add(this.cells.eq(end - 1).next('.listView-secondaryRow'));
        return rv;
    },
    actionsForRow: function(row) {
        return $('.listView-action', this.cellsForRow(row));
    },
    activateMainAction: function(row) {
        var action = this.actionsForRow(row).last();
        if(action.data('menu')) {
            action.trigger('key-activate');
        } else {
            action.click();
        }
    },
    activateMainActionFromClick: function(row, evt) {
        var action = this.actionsForRow(row).last();
        if(action.data('menu')) {
            action.data('menu').dropdown('show', {
                event: evt,
                button: action,
                data: {
                    row: row,
                    selection: this.calcSelection(action)
                }
            });
        } else {
            action.click();
        }
    },
    checkboxForRow: function(row) {
        return $(':checkbox', this.cellsForRow(row).filter('.listView-checkbox'));
    },
    calcRow: function(elt) {
        // Calulate which row an element is in
        return $(elt).closest(this.cells).data('row');
    },
    calcSelection: function(button) {
        if(button.data('selection')) {
            return button.data('selection');
        } else {
            var row = this.calcRow(button);
            return $('input[name=selection]', this.cellsForRow(row)).val();
        }
    },
    setHoverRow: function(row) {
        if(row == this.hoverRow) {
            return;
        }
        this.hoverRow = row;
        this.updateHoverRow();
    },
    onDropdownShown: function(evt, data) {
        this.contextMenuRow = this.calcRow(data.openerButton);
        this.updateHoverRow();
    },
    onDropdownHidden: function(evt, data) {
        this.contextMenuRow = null;
        this.updateHoverRow();
    },
    updateHoverRow: function(evt, data) {
        if(this.contextMenuRow) {
            // If there's a context menu shown, then keep this as the hover
            // row, regardless of where the mouse goes.
            var row = this.contextMenuRow;
        } else {
            var row = this.hoverRow;
        }
        $('.hover', this.elt).removeClass('hover');
        if(row !== null && row !== undefined) {
            this.actionsForRow(row).addClass('hover');
            this.cellsForRow(row).addClass('hover');
        }
    }
};

function ListViewExpansion(dom) {
    this.dom = dom;
    this.expandedRow = null;

    this.dom.elt.on('toggle-row-expanded', this.onToggleRowExpanded.bind(this));
    _.each(this.dom.dropdownMenusByRow, function(menu, row) {
        menu.on('link-activate', null, parseInt(row), this.onLinkActivate.bind(this));
    }, this);
}

ListViewExpansion.prototype = {
    toggleRowExpanded: function(row) {
        if(this.expandedRow == row) {
            this.collapseRow(row);
        } else {
            this.expandRow(row);
        }
    },
    expandRow: function(row) {
        if(this.expandedRow) {
            if(this.expandedRow == row) {
                return;
            }
            this.collapseRow(this.expandedRow);
        }
        var cells = this.dom.cellsForRow(row);
        cells.addClass('expanded');

        $('.listView-secondary', cells).slideDown(250);
        cells.filter('.listView-secondaryRow').slideDown(250);
        this.updateShowDetailsText(row, gettext('Hide Details'));
        this.dom.elt.trigger($.Event('row-expanded', { row: row}));
        this.expandedRow = row;
    },
    collapseRow: function(row) {
        var cells = this.dom.cellsForRow(row);
        cells.removeClass('expanded');

        $('.listView-secondary', cells).slideUp(250);
        cells.filter('.listView-secondaryRow').slideUp(250);
        this.updateShowDetailsText(row, gettext('Show Details'));
        this.dom.elt.trigger($.Event('row-collapsed', { row: row}));
        this.expandedRow = null;
    },
    updateShowDetailsText: function(row, text) {
        if(row != null && this.dom.showDetailsByRow[row].length > 0) {
            $('.dropdownMenu-text', this.dom.showDetailsByRow[row]).text(text);
        }
    },
    onToggleRowExpanded: function(evt, row) {
        if(row >= this.dom.headerRowCount) {
            this.toggleRowExpanded(row);
        }
    },
    onLinkActivate: function(evt, action) {
        if(action == 'expand') {
            this.toggleRowExpanded(evt.data);
        }
    }
};

function ListViewMouse(dom) {
    this.dom = dom;
    this.touchTimer = null;
    this.touchStartEvt = null;
    this.sawTouch = false
    this.showedContextMenu = false;

    dom.cells.click(this.onCellClick.bind(this));
    dom.cells.mouseenter(this.onMouseEnterCell.bind(this));
    dom.elt.mouseleave(this.onMouseLeaveListView.bind(this));
    dom.cells.on('touchstart', this.onTouchStart.bind(this));
    dom.cells.on('touchend', this.onTouchEnd.bind(this));
    dom.cells.on('touchcancel', this.onTouchCancel.bind(this));
    dom.cells.on('touchmove', this.onTouchMove.bind(this));
}

ListViewMouse.prototype = {
    onCellClick: function(evt) {
        var target = $(evt.target);
        // Allow link/button clicks to go through
        if(target.closest('a, button, input, textarea, .checkbox').length > 0) {
            return;
        }

        // Make clicks in a checkbox cell toggle the checkbox
        var checkboxCell = target.closest('.listView-checkbox');
        if(checkboxCell.length) {
            $(':checkbox', checkboxCell).each(function () {
                this.checked = !this.checked;
            }).change();
            return false;
        }

        // Make clicks in an expandable list expand the row
        if(this.dom.elt.is('.expandable')) {
            this.dom.elt.triggerHandler('toggle-row-expanded', this.dom.calcRow(target));
            return false;
        }
    },
    onMouseEnterCell: function(evt) {
        // Enable the hover CSS, only if we're not on a touch device.  For
        // those we get mouseenter events when the user touches a row, which
        // feels weird..
        if(!this.sawTouch) {
            this.dom.setHoverRow($(evt.target).data('row'));
        }
    },
    onMouseLeaveListView: function(evt) {
        this.dom.setHoverRow(null);
    },
    onTouchStart: function(evt) {
        this.sawTouch = true;
        if(evt.touches.length == 1) {
            this.touchStartEvt = evt;
            this.startTouchTimer();
            $(window).on('contextmenu.listviewmouse', function(evt) {
                // Prevent the default context menu since we're going to present our own
                evt.preventDefault();
                evt.stopPropagation();
            });
        }
        this.showedContextMenu = false;
    },
    onTouchEnd: function(evt) {
        this.cancelTouchTimer();
        $(window).off('contextmenu.listviewmouse');
        this.showedContextMenu = false;
    },
    onTouchCancel: function(evt) {
        this.onTouchEnd(evt);
    },
    onTouchMove: function(evt) {
        this.cancelTouchTimer();
        if(this.showedContextMenu) {
            evt.preventDefault();
        }
    },
    startTouchTimer: function() {
        this.cancelTouchTimer();
        this.touchTimer = setTimeout(this.onTouchTimer.bind(this), longTouchDelay);
    },
    cancelTouchTimer: function() {
        if(this.touchTimer) {
            clearTimeout(this.touchTimer);
        }
        this.touchTimer = null;
    },
    onTouchTimer: function() {
        this.touchTimer = null;
        this.showedContextMenu = true;
        var row = this.dom.calcRow(this.touchStartEvt.target);
        this.dom.activateMainActionFromClick(row, this.touchStartEvt);
    }
};

function ListViewKeys(dom) {
    this.dom = dom;
    this.selectedRow = null;

    dom.elt.on('keydown', this.onKeyDown.bind(this));
    dom.elt.on('focusout', this.onFocusOut.bind(this));
    dom.elt.on('focusin', this.addSelectedStyles.bind(this));
    dom.elt.on('row-expanded', this.onRowExpanded.bind(this));
    this.dom.dropdownMenus.on('focus-button', this.onDropdownMenuFocusButton.bind(this));
}

ListViewKeys.prototype = {
    onKeyDown: function(evt) {
        if(evt.which == keyCodes.up) {
            this.selectPreviousRow();
        } else if(evt.which == keyCodes.down) {
            this.selectNextRow();
        } else if(evt.which == keyCodes.space) {
            this.toggleCheckbox();
        } else if(evt.which == keyCodes.enter) {
            this.activateMenu();
        } else if(evt.ctrlKey && String.fromCharCode(evt.which).toLowerCase() == 'a') {
            this.selectAll();
        } else {
            // Unhandled key, return now to avoid calling preventDefault();
            return;
        }
        evt.preventDefault();
    },
    selectNextRow: function() {
        if(this.selectedRow === null) {
            this.selectRow(this.dom.headerRowCount);
        } else if(this.selectedRow + 1 < this.dom.rowCount) {
            this.selectRow(this.selectedRow + 1);
        } else {
            this.selectRow(null);
        }
    },
    selectPreviousRow: function() {
        if(this.selectedRow === null) {
            this.selectRow(this.dom.rowCount - 1);
        } else if(this.selectedRow - 1 >= this.dom.headerRowCount) {
            this.selectRow(this.selectedRow - 1);
        } else {
            this.selectRow(null);
        }
    },
    selectRow: function(row) {
        this.removeSelectedStyles();
        this.selectedRow = row;
        this.addSelectedStyles();
    },
    removeSelectedStyles: function() {
        this.dom.cellsForRow(this.selectedRow).first().removeClass('selected');
        this.dom.actionsForRow(this.selectedRow).last().removeClass('selected');
    },
    onFocusOut: function(evt) {
        if($(evt.relatedTarget).closest(this.dom.dropdownMenus).length == 0) {
            this.removeSelectedStyles();
        }
    },
    addSelectedStyles: function() {
        this.dom.cellsForRow(this.selectedRow).first().addClass('selected');
        this.dom.actionsForRow(this.selectedRow).last().addClass('selected');
    },
    toggleCheckbox: function() {
        var checkbox = this.dom.checkboxForRow(this.selectedRow);
        if(checkbox.length > 0) {
            checkbox.prop('checked', !checkbox.prop('checked')).trigger('change');
        }
    },
    activateMenu: function() {
        this.dom.activateMainAction(this.selectedRow);
    },
    selectAll: function() {
        this.dom.checkAll.prop('checked', !this.dom.checkAll.prop('checked')).trigger('change');
    },
    onDropdownMenuFocusButton: function() {
        // When we hide dropdowns inside the listview, don't focus the button.
        // Instead, focus the listView, so the user can continue working with
        // it.
        this.dom.elt.focus();
        return false;
    },
    onRowExpanded: function(evt) {
        // If there is a currently expanded row, change it to the expanded one
        if(this.selectedRow) {
            this.selectRow(evt.row);
        }
    }
};

function listView(elt) {
    var dom = new ListViewDOM(elt);
    var expansion = new ListViewExpansion(dom);
    var mouse = new ListViewMouse(dom);
    var keys = new ListViewKeys(dom);
}
//Ensure full nav cycle on listview video page
$(".user-menu").on("keydown", function(event) {
if (event.key === 'Tab') {
    const focusedElement = $(document.activeElement);

    if (focusedElement.attr('tabindex') === '0') {
    const tabIndexZeroElements = $('[tabindex="0"]');
    const currentIndex = tabIndexZeroElements.index(focusedElement);
    const numElements = tabIndexZeroElements.length;

    if (event.shiftKey) {
        // Shift + Tab: Move focus to the previous element
        const prevIndex = (currentIndex - 1 + numElements) % numElements;
        tabIndexZeroElements.eq(prevIndex).focus();
        event.preventDefault();
    } else {
        // Tab: Move focus to the next element
        const nextIndex = (currentIndex + 1) % numElements;
        tabIndexZeroElements.eq(nextIndex).focus();
        event.preventDefault();
    }
    }
}
});

$(".filterBox-button").on("keydown", function(event) {
    if (event.key === 'Tab') {
      const filterBoxButtons = Array.from(document.querySelectorAll('.filterBox-button'));
      const currentIndex = filterBoxButtons.indexOf(this);
      const searchBarInput = document.querySelector('.contentHeader-searchBar');
      const checkAllCheckbox = document.querySelector('.checkAll');

      if (event.shiftKey) {
        // Shift + Tab: Move focus to the previous element
        if (currentIndex === 0) {
          event.preventDefault();
          searchBarInput.focus();
        }
      } else {
        // Tab: Move focus to the next element
        if (currentIndex === filterBoxButtons.length - 1) {
          event.preventDefault();
          checkAllCheckbox.focus();
        }
      }
    }
  });

  document.addEventListener('DOMContentLoaded', function() {
    // Find all .tabCycleDropdown buttons
    const tabCycleDropdownButtons = document.querySelectorAll('.tabCycleDropdown');
    const actionBarDeselectBtn = document.getElementById('actionBar-deselect-btn');
    // Remove the "last" class from all .tabCycleDropdown buttons (in case it was previously set)
    tabCycleDropdownButtons.forEach(button => button.classList.remove('last'));

    // Add the "last" class to the last .tabCycleDropdown button
    const lastTabCycleDropdownButton = tabCycleDropdownButtons[tabCycleDropdownButtons.length - 1];
    if (lastTabCycleDropdownButton) {
      lastTabCycleDropdownButton.classList.add('last');

      // Handle keydown event on the last .tabCycleDropdown button with class .last
      lastTabCycleDropdownButton.addEventListener('keydown', function(event) {
        if (event.key === 'Tab') {
          if (event.shiftKey) {
            const tabCycleLink = document.activeElement.closest('.tabCycleLink');
            if (tabCycleLink && tabCycleLink !== lastTabCycleDropdownButton) {
              tabCycleLink.focus();
            }
          } else {
            if (actionBarDeselectBtn) { // Check if actionBarDeselectBtn exists
              actionBarDeselectBtn.focus();
            }
          }
        }
      });
    }

    if (actionBarDeselectBtn) { // Check if actionBarDeselectBtn exists before adding the event listener
      actionBarDeselectBtn.addEventListener('keydown', function(event) {
        if (event.shiftKey && event.key === 'Tab' && actionBarDeselectBtn === document.activeElement) {
          // Shift + Tab: Shift key is pressed, Tab key is pressed, and focus is on #actionBar-deselect-btn
          event.preventDefault();
          if (lastTabCycleDropdownButton) {
            lastTabCycleDropdownButton.focus();
            lastTabCycleDropdownButton.classList.add('test-class'); // Add "test-class"
          }
        }
      });
    }
  });


// Handle tab navigation within .tabCycleDropdown buttons
$(".tabCycleDropdown").on("keydown", function(event) {
    if (event.key === 'Tab') {
    const focusedElement = $(document.activeElement);

    if (focusedElement.attr('tabindex') === '0') {
        const tabIndexZeroElements = $('[tabindex="0"]');
        const currentIndex = tabIndexZeroElements.index(focusedElement);
        const numElements = tabIndexZeroElements.length;

        if (event.shiftKey) {
        // Shift + Tab: Move focus to the previous element
        const prevIndex = (currentIndex - 1 + numElements) % numElements;
        tabIndexZeroElements.eq(prevIndex).focus();
        event.preventDefault();
        } else {
        // Tab: Move focus to the next element
        const nextIndex = (currentIndex + 1) % numElements;
        tabIndexZeroElements.eq(nextIndex).focus();
        event.preventDefault();
        }
    }
    }
});
$(".videoCard2-contextMenu").on("keydown", function(event) {
if (event.key === 'Tab') {
    const focusedElement = $(document.activeElement);

    if (focusedElement.attr('tabindex') === '0') {
    const tabIndexZeroElements = $('[tabindex="0"]');
    const currentIndex = tabIndexZeroElements.index(focusedElement);
    const numElements = tabIndexZeroElements.length;

    if (event.shiftKey) {
        // Shift + Tab: Move focus to the previous element
        const prevIndex = (currentIndex - 1 + numElements) % numElements;
        tabIndexZeroElements.eq(prevIndex).focus();
        event.preventDefault();
    } else {
        // Tab: Move focus to the next element
        const nextIndex = (currentIndex + 1) % numElements;
        tabIndexZeroElements.eq(nextIndex).focus();
        event.preventDefault();
    }
    }
}
});
// Listview keyboard nav including dropdown display handling
const dropdownMenubuttons = document.querySelectorAll('.tabCycleDropdown');
const menuItems = document.querySelectorAll('.dropdownMenu-link');
const dropdownMenu = document.querySelectorAll('.listActionDropdown');
let activatedButton = null; // Variable to store the button that activated the menu

// Event listener to track the button that activates the menu
dropdownMenubuttons.forEach(dropdownMenubutton => {
    dropdownMenubutton.addEventListener('keydown', function() {
    activatedButton = this; // Store the button that activated the menu
  });
});
menuItems.forEach(menuItem => {
  menuItem.addEventListener('keydown', function(event) {
    if (event.key === 'Tab' || (event.key === 'Tab' && event.shiftKey)) {
      // Tab or Shift+Tab: Hide the dropdown menu
      $(dropdownMenu).dropdown('hide');
      activatedButton.focus();
      event.preventDefault();
    }
  });
});

const checkAll = document.querySelector('.checkAll');
const listViewCheckboxes = document.querySelectorAll('.table-checkbox input[type="checkbox"]');
const actionBar = document.querySelector('.actionBar');

function toggleCheckbox(checkbox) {
  checkbox.checked = !checkbox.checked;
}

function updateCheckAll() {
  const allChecked = Array.from(listViewCheckboxes).every(checkbox => checkbox.checked);
  checkAll.checked = allChecked;
}

// Variable to track the state of the checkAll checkbox
let checkAllState = null; // Initialize as null

document.addEventListener('DOMContentLoaded', function() {
  // Find and store the 'checkAll' element if it exists
  const checkAll = document.querySelector('.checkAll');
  if (checkAll) {
    checkAllState = checkAll.checked;

    document.addEventListener('change', function(event) {
      if (event.target.classList.contains('checkAll')) {
        for (const checkbox of listViewCheckboxes) {
          checkbox.checked = event.target.checked;
        }
        checkAllState = event.target.checked;
        updateCheckAll();
      } else if (event.target.closest('.listView-checkbox input[type="checkbox"]')) {
        updateCheckAll();
      }
    });

    document.addEventListener('keydown', function(event) {
      if (event.key === ' ' || event.key === 'Spacebar') {
        if (document.activeElement.classList.contains('checkAll')) {
          event.preventDefault();
          toggleCheckbox(checkAll);
          for (const checkbox of listViewCheckboxes) {
            checkbox.checked = checkAll.checked;
          }
          checkAllState = checkAll.checked;
          updateCheckAll();
        } else if (document.activeElement.closest('.table-checkbox input[type="checkbox"]')) {
          event.preventDefault();
          const checkbox = document.activeElement.closest('.table-checkbox input[type="checkbox"]');
          toggleCheckbox(checkbox);
          updateCheckAll();

          // Check if all .listView-checkbox checkboxes are checked again
          const allChecked = Array.from(listViewCheckboxes).every(checkbox => checkbox.checked);

          // If the checkAll state differs from the previous state and all are checked again, reset the checkAll state
          if (checkAllState !== checkAll.checked && allChecked) {
            toggleCheckbox(checkAll);
            checkAllState = checkAll.checked;
            updateCheckAll();
          }
        }
      }
    });
  }
});
