/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2018 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

var $ = require('jquery');
var ajax = require('./ajax');
var filters = require('./filters');
var querystring = require('./querystring');
var keyCodes = require('./keyCodes');
var position = require('./position');
var copyText = require('./copyText');

// Create a jquery plugin to handle dropdown stuff.  API:
//
//   - $(elt).dropdown(): initialize dropdown code (not needed if you add the .dropdownMenu class)
//   - $(elt).dropdown('show', context): show the dropdown
//   - $(elt).dropdown('hide', context): hide the dropdown
//   - $(elt).dropdown('toggle', context): toggle the dropdown
//   - $(elt).dropdown('focusFirstLink'): focus the first link
//   - $(elt).dropdown('focusLastLink'): focus the last link
//
//   In general, you can call any method of the DropDownMenu class.  The "context" param is a dict with these keys:
//     - button -- button element that caused the dropdown to show/hide (if applicable)
//     - event -- current jQuery event
//
$.fn.dropdown = function(action) {
    if(action === undefined) {
        return this.each(setupDropdownMenu);
    } else {
        var extraArgs = Array.prototype.slice.call(arguments, 1);
        return this.each(function() {
            var dropdown = $(this).data('dropdown');
            var method = dropdown[action];
            if(method) {
                method.apply(dropdown, extraArgs);
            } else {
                throw "Unknown dropdown action: " + action;
            }
        });
    }
}

$.behaviors('.dropdownMenu', setupDropdownMenu);
$.behaviors('.dropdownMenu-button', dropdownMenuButton);

function dropdownMenuButton(button) {
    var button = $(button);
    var menu = $();

    if(button.data('menu')) {
      menu = button.data('menu');
    } else if(button.data('target')) {
        menu = $('#' + button.data('target'));
        button.data('menu', menu);
    }

    function is_disabled() {
        return button.hasClass('disabled');
    }

    button.click(function(evt) {
        if (is_disabled()) {
            return;
        }
        menu.dropdown('toggle', {button: button, event: evt});
        evt.preventDefault();
    }).keydown(function(evt) {
        if (is_disabled()) {
            return;
        }
        if(evt.which == keyCodes.enter ||
                evt.which == keyCodes.space ||
                evt.which == keyCodes.down) {
            menu.dropdown('show', {button: button, event: evt});
            menu.dropdown('focusFirstLink');
        } else if(evt.which == keyCodes.up) {
            menu.dropdown('show', {button: button, event: evt});
            menu.dropdown('focusLastLink');
        } else if(evt.which == keyCodes.esc) {
            menu.dropdown('hide', {button: button, event: evt});
        }
        evt.stopPropagation();
        evt.preventDefault();
    }).on('key-activate', function(evt) {
        if (is_disabled()) {
            return;
        }
        menu.dropdown('show', {button: button, event: evt});
        menu.dropdown('focusFirstLink');
    });
}

function setupDropdownMenu(menu) {
    menu = $(menu);
    if(!menu.data('dropdown')) {
        menu.data('dropdown', new DropDownMenu(menu));
    }
}

function DropDownMenu(menu) {
    this.menu = menu;
    this.shown = false;
    this.openerButton = null;
    this.showData = null;
    this.calcLinks();

    // additional options for position.below
    this.positionOptions = {}
    this.positionOptions.dropLeft = menu.hasClass('dropdownMenuLeft')

    this.setupEventHandlers();
    this.updateFilterChecks();
    filters.callbacks.add(this.updateFilterChecks.bind(this));
}

DropDownMenu.prototype = {
    show: function(context) {
        if(context === undefined) {
            context = {};
        }
        var positionOptions = _.defaults(context.positionOptions, this.positionOptions);
        // The 'show' event gets sent early, so handlers have an opportunity to prevent the action
        var showEventData = {
            dropdownMenu: this,
            showData: context.data,
            button: context.button,
            event: context.event
        };
        if(this.menu.triggerHandler('show', showEventData) === false) {
            return;
        }

        if(this.shown) {
            if(context.button && context.button == this.openerButton) {
                return;
            } else {
                this.hide(context);
            }
        }
        // hide all other menus;
        $('.dropdownMenu:visible').not(this.menu).dropdown('hide', context);
        this.menu.css('display', 'flex');
        if(context.button) {
            context.button.attr('aria-expanded', 'true');
        }
        this.openerButton = context.button;
        this.showData = context.data;
        this.shown = true;
        this.ignoreClick = context.ignoreClick;
        this.setupClickHandler();
        this.resetDisabledAndHidden();
        this.setupDisabledItems();
        this.setupHiddenItems();
        this.setupExtraLinks();
        if(context.event) {
            context.event.preventDefault();
            context.event.stopPropagation();
        }
        // Show the menu
        if(context.button && context.button.is(':visible')) {
            position.below(this.menu, context.button, positionOptions);
        } else if (context.anchor) {
            position.below(this.menu, context.anchor, positionOptions);
        } else {
            position.below(this.menu, context.event, positionOptions);
        }
        // The 'shown' event gets sent late, so handlers know the action has succeeded
        this.menu.triggerHandler('shown', showEventData);
    },
    hide: function(context) {
        if (context === undefined) {
            context = {};
        }
        if (!this.shown) {
            return;
        }
        // The 'hide' event gets sent early, so handlers have an opportunity to prevent the action
        var hideEventData = { dropdownMenu: this, showData: this.showData, openerButton: this.openerButton };
        if (this.menu.triggerHandler('hide', { dropdownMenu: this, openerButton: this.openerButton }) === false) {
            return;
        }

        this.menu.css('display', 'none');
        if (this.openerButton) {
            this.openerButton.attr('aria-expanded', 'false');
            this.openerButton = null;
        }
        this.shown = false;
        this.showData = null;
        this.removeClickHandler();
        if (context.event && !context.skipPreventDefault) {
            context.event.preventDefault();
            context.event.stopPropagation();

            // Logic to reopen the dropdown on pressing Enter after it's closed
            if (context.event.which === keyCodes.enter) {
                // Show the dropdown again after a small delay
                var self = this;
                setTimeout(function () {
                    self.show(context);
                }, 100); // Adjust the delay time as needed
            }
        }
        // The 'hidden' event gets sent late, so handlers know the action has succeeded
        this.menu.triggerHandler('hidden', hideEventData);
    },

    toggle: function(context) {
        if(this.shown && this.openerButton === context.button) {
            this.hide(context);
        } else {
            this.show(context);
        }
    },
    calcLinks: function() {
        this.links = $('.dropdownMenu-link', this.menu).not('.disabled');
    },
    focusedLinkIndex: function() {
        var link = this.links.filter(':focus');
        if(link.length > 0) {
            return this.links.index(link);
        } else {
            return -1;
        }
    },
    focusFirstLink: function() {
        this.links.get(0).focus();
    },
    focusLastLink: function() {
        this.links.get(-1).focus();
    },
    focusNextLink: function() {
        var index = (this.focusedLinkIndex() + 1) % this.links.length;
        this.links.get(index).focus();
    },
    focusPrevLink: function() {
        var index = this.focusedLinkIndex() - 1;
        this.links.get(index).focus();
    },
    focusNextLinkWithChar: function(character) {
        var startingIndex = i = this.focusedLinkIndex();
        while(true) {
            i = (i + 1) % this.links.length;
            if(i == startingIndex) {
                return;
            }
            var currentLink = this.links.eq(i);
            if(currentLink.text().trim()[0].toUpperCase() == character) {
                currentLink.focus();
                return;
            }
        }
    },
    setupEventHandlers: function(links) {
        if(links === undefined) {
          links = this.links;
        }
        var self = this;

        links.on('keydown', function(evt) {
            if(evt.which == keyCodes.up) {
                self.focusPrevLink();
            } else if (evt.which == keyCodes.down) {
                self.focusNextLink();
            } else if(evt.which == keyCodes.enter) {
                self.activateLink(evt, $(this));
            } else if(evt.which == keyCodes.esc) {
                var button = self.openerButton;
                self.hide();
                self.focusButton(button);
            } else if(evt.which == keyCodes.home) {
                self.focusFirstLink();
            } else if(evt.which == keyCodes.end) {
                self.focusLastLink();
            } else if(keyCodes.isAlpha(evt.which)) {
                self.focusNextLinkWithChar(String.fromCharCode(evt.which));
            }

            evt.stopPropagation();
            evt.preventDefault();
        }).on('click', function(evt) {
            self.activateLink(evt, $(this));
        });
      // Add a focusout event handler to hide the dropdown when focus moves away
      links.on('focusout', function(evt) {
        var isInsideMenu = $(evt.relatedTarget).closest('.dropdownMenu').length > 0;
        if (!isInsideMenu) {
            this.hide();
        }
    }.bind(this));
    },
    activateLink: function(evt, link) {
        var button = this.openerButton;
        var activateArgs = link.data('activateArgs');
        if(activateArgs) {
            var showData = this.showData;
            // dropdown-js-item -- trigger link-activate
            this.hide({button: button, event: evt });
            if(button) {
                this.focusButton(button);
            }
            this.linkActivateDefault(activateArgs, button);
            this.menu.trigger($.Event('link-activate', {
                openerButton: button,
                showData: showData,
                dropdownMenu: this,
                link: link,
                isAutocompleteFilter: link[0].hasAttribute('data-is-autocomplete-filter'),
            }), activateArgs);
            evt.preventDefault();
        } else {
            this.hide({button: button, event: evt, skipPreventDefault:true });
            // Regular link item.  Don't call preventDefault() to make the link
            // click go through.  Also, skip calling focusButton, since that would
            // stop the click.
        }
    },
    linkActivateDefault: function(args, openerButton) {
        // Handle default link-activate actions.
        if(args[0] === 'copy-text') {
            copyText(openerButton.data(args[1]));
        } else if(args[0] === 'update-filter') {
            var name = args[1];
            var value = args[2];
            filters.add(name, value);
        } else if(args && args[0] === 'link-from-button') {
            var attrName = args[1];
            var extra = args[2];
            var url = openerButton.data(attrName);
            if(url) {
                if(extra == 'ajax') {
                    ajax.update(url, {keepState: true});
                } else {
                    window.location = url;
                }
            } else {
                console.warn('link-from-button ' + attrName + ' not found on button');
            }

        } else if(args && args[0] === 'data-from-button') {
            var url = args[1];
            var attrName = args[2];
            var extra = args[3];

            var value = openerButton.data(attrName);
            if(value) {
                var queryParam = attrName + '=' + encodeURIComponent(value);
                if(url.indexOf('?') == -1) {
                    url += '?' + queryParam;
                } else {
                    url += '&' + queryParam;
                }
                if(extra == 'ajax') {
                    ajax.update(url, {keepState: true});
                } else {
                    window.location = url;
                }
            } else {
                console.warn('link-from-button ' + attrName + ' not found on button');
            }
        }

    },
    updateFilterChecks: function() {
        var qs = querystring.parseList();
        var checkedFilters = {};
        this.links.each(function() {
            var link = $(this);
            var activateArgs = link.data('activateArgs');
            if(activateArgs && activateArgs[0] == 'update-filter') {
                var name = activateArgs[1];
                var value = activateArgs[2];
                var isDefault = (activateArgs.length >= 5 && activateArgs[4] == 'default');
                $('.dropdownMenu-icon', link).remove();
                if((qs[name] && qs[name].indexOf(value) > -1) ||
                        (qs[name] === undefined && isDefault)) {
                    link.prepend($('<span class="fa fa-check dropdownMenu-icon">'));
                    checkedFilters[name] = true;
                }
            }
        });
        // Go through other-choice-checkmark options
        this.links.each(function() {
            var link = $(this);
            var activateArgs = link.data('activateArgs');
            if(activateArgs && link.hasClass('other-choice-checkmark')) {
                var name = activateArgs[0];
                if(checkedFilters[name] || !qs[name]) {
                    $('.dropdownMenu-icon', link).remove();
                } else {
                    link.prepend($('<span class="fa fa-check dropdownMenu-icon">'));
                }
            }
        });
    },
    focusButton: function(button) {
        var rv = this.menu.triggerHandler('focus-button');
        if(rv !== false && this.openerButton) {
            this.openerButton.focus();
        }
    },
    setupClickHandler: function() {
        this.clickHandler = this.onClickWithOpenDropdown.bind(this);
        document.addEventListener('click', this.clickHandler, true);
    },
    removeClickHandler: function() {
        document.removeEventListener('click', this.clickHandler, true);
        this.clickHandler = null;
    },
    setupDisabledItems: function() {
        var links = $('.dropdownMenu-link', this.menu)
        links.each(function() {
            var link = $(this);
            link.attr('disabled', null);
            link.removeClass('disabled');
            link.removeData('autodisabled');
        });
        if(this.openerButton && this.openerButton.data('disable')) {
            var disable = this.openerButton.data('disable');
            if(!Array.isArray(disable)) {
                disable = [disable];
            }
            for(var i=0; i < disable.length; i++) {
                this.links.each(function() {
                    var link = $(this);
                    if(link.closest('li').hasClass(disable[i])) {
                        link.attr('disabled', 'disabled');
                        link.data('autodisabled', true);
                        link.addClass('disabled');
                    }
                });
            }
        }
    },
    setupHiddenItems: function() {
        if(this.openerButton && this.openerButton.data('hide')) {
          var hiddenClass = this.openerButton.data('hide');
          this.links.each(function() {
              var link = $(this);
              if(link.closest('li').hasClass(hiddenClass)) {
                  link.css('display', 'none');
                  link.data('autohidden', true);
              }
          });
        }
    },
    setupExtraLinks: function() {
      this.removeExtraLinkItems();
      if(this.openerButton && this.openerButton.data('extraLinks')) {
        var extraLinks = this.openerButton.data('extraLinks');
        for(var i=0; i < extraLinks.length; i++) {
          this.appendExtraLinkItem(extraLinks[i][0], extraLinks[i][1]);
        }
      }
    },
    resetDisabledAndHidden: function() {
      this.links.each(function() {
        var link = $(this);
        if(link.data('autodisabled')) {
          link.attr('disabled', null);
          link.removeClass('disabled');
          link.removeData('autodisabled');
        }
        if(link.data('autohidden')) {
          link.css('display', 'list-item')
          link.removeData('autohidden');
        }
      });
    },
    onClickWithOpenDropdown: function(evt) {
        var target = $(evt.target);
        if(this.ignoreClick && this.ignoreClick(evt, target)) {
            return;
        }
        if(target.closest(this.menu).length == 0 &&
                target.closest(this.openerButton).length == 0) {
            this.hide({event:evt, skipPreventDefault: true});
        }
    },
    setItems: function(items) {
        this.menu.empty();
        $.each(items, function(i, item) {
            this.menu.append(this.makeLI(item));
        }.bind(this));
        this.calcLinks();
        this.setupEventHandlers();
    },
    prependItem: function(item) {
      var li = this.makeLI(item);
      this.menu.prepend(li);
      this.calcLinks();
      this.setupEventHandlers($('.dropdownMenu-link', li));
      return li;
    },
    appendItem: function(item) {
      var li = this.makeLI(item);
      this.menu.append(li);
      this.calcLinks();
      this.setupEventHandlers($('.dropdownMenu-link', li));
      return li;
    },
    removeItem: function(index) {
      this.menu.children().eq(index).remove();
      this.calcLinks();
    },
    appendExtraLinkItem: function(text, href) {
      this.appendItem({ text: text, href: href}).addClass('extraLink');
    },
    removeExtraLinkItems: function() {
      this.menu.children().filter('.extraLink').remove();
      this.calcLinks();
    },
    makeLI: function(item) {
        var attrs = {
            class: 'dropdownMenu-link',
            role: 'menuitem',
            tabindex: '-1'
        };
        if(item.href) {
          // If href is present, make an <a> tag
          attrs.href = item.href;
          var elt = $('<a>', attrs);
        } else {
          // Otherwise, make a button
          elt = $('<button>', attrs);
          if(item.args) {
            elt.data('activateArgs', item.args);
          }
        }
        if(item.content) {
            elt.append(item.content);
        } else if(item.text) {
            elt.text(item.text);
        }
        if(item.icon) {
            elt.prepend('<span>', {
                class: "dropdownMenu-icon " + item.icon
            });
        }
        return $('<li class="dropdownMenu-item">').append(elt);
    }
};
$.behaviors('.dropdownMenu-button.subtitlingServicesnavbar', dropdownMenuButtonSubServicesNavbar);

function dropdownMenuButtonSubServicesNavbar(button) {
    var button = $(button);
    var menu = $();

    if (button.data('menu')) {
        menu = button.data('menu');
    } else if (button.data('target')) {
        menu = $('#' + button.data('target'));
        button.data('menu', menu);
    }

    function is_disabled() {
        return button.hasClass('disabled');
    }

    let hideTimeout;

    // Function to show the menu
    function showMenu(evt) {
        clearTimeout(hideTimeout); // Cancel any pending hide action
        menu.dropdown('show', { button: button, event: evt });
    }

    // Function to hide the menu after a delay
    function hideMenuDelayed(evt) {
        hideTimeout = setTimeout(function() {
            // Check if the cursor is not hovering over the button or menu
            if (!button.is(evt.relatedTarget) && !button.has(evt.relatedTarget).length && !menu.is(evt.relatedTarget) && !menu.has(evt.relatedTarget).length) {
                menu.dropdown('hide', { button: button, event: evt });
            }
        }, 200); // Adjust the delay time as needed
    }

    // Add mouseenter and mouseleave event handlers to the button
    button.on('mouseenter', function(evt) {
        if (!is_disabled()) {
            showMenu(evt);
        }
    }).on('mouseleave', function(evt) {
        hideMenuDelayed(evt);
    });

    // Add mouseenter and mouseleave event handlers to the menu
    menu.on('mouseenter', function(evt) {
        clearTimeout(hideTimeout); // Cancel any pending hide action
    }).on('mouseleave', function(evt) {
        hideMenuDelayed(evt);
    });

    menu.find('.dropdownMenu-link').on('click', function(evt) {
        const url = $(this).attr('href');
        if (url) {
            window.location.href = url;
        }
    });

    menu.on('keydown', function(evt) {
        clearTimeout(hideTimeout); // Cancel any pending hide action
        evt.stopPropagation(); // Prevent event propagation to avoid triggering body's keydown event

        if (evt.which === keyCodes.up || evt.which === keyCodes.down) {
            evt.preventDefault(); // Prevent default browser behavior of scrolling the page
        }

        if (evt.which === keyCodes.enter) {
            const activeElement = $(document.activeElement);
            const menuLink = activeElement.closest('.dropdownMenu-link');
            if (menuLink.length) {
                menuLink[0].click(); // Simulate a click event on the focused link
            }
        }
    });

    menu.find('.dropdownMenu-link').on('click', function(evt) {
        const url = $(this).attr('href');
        if (url) {
            window.location.href = url;
        }
    });
}



module.exports = {
    create: function(id, items, options) {
        if(options === undefined) {
            options = {};
        }
        var menu = $('#' + id);
        if(menu.length == 0) {
            var menu = $('<ul>', {
                id: id,
                class: 'dropdownMenu',
                role: 'menu'
            });
            if(options.dropLeft) {
                menu.addClass('dropdownMenuLeft')
            }
            if(options.noIcon) {
                menu.addClass('no-icon');
            }
        }
        setupDropdownMenu(menu);
        return menu;
    }
}

